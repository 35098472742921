@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@900&display=swap');

.cake-container{
    background-color: darkblue;
    width: 100vw;
    height:100%;
    box-sizing: border-box;
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
.firework{
    mix-blend-mode: lighten;
    background-clip: padding-box;
    box-shadow: inset 0px 0px 30px 20px darkblue;
    position: absolute;
    width: 100%;
    height: 300px;
    top:0;
    background-image: url(https://usagif.com/wp-content/uploads/gify/fireworks-6-usagif.gif);
    background-size: 130%;
    animation: come 1s ease-in;
    @keyframes come {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
}
 .cake {
    position: relative;
    width: 200px;
    height: 100px;
    background-image: url(https://i.gifer.com/embedded/download/3SZU.gif);
    background-size: contain;
    border-radius: 10px;
    box-shadow: 0 0 10px #888888;
    margin: 0 auto;
  }
   .candles {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: space-around;
  }
   .candle {
    position: relative;
    width: 10px;
    height: 50px;
    background: linear-gradient(
        45deg,
        rgba(255,50,50,100) 0%,
        rgba(200,10,10,100) 10%,
        rgba(255,50,50,100) 20%,
        rgba(200,10,10,100) 30%,
        rgba(255,50,50,100) 40%,
        rgba(200,10,10,100) 50%,
        rgba(255,50,50,100) 60%,
        rgba(200,10,10,100) 70%,
        rgba(255,50,50,100) 80%,
        rgba(200,10,10,100) 90%,
    );
    .candle-lit{
        position: absolute;
        top:-20px;
        width: 100%;
        height: 20px;
        border-radius: 0px 50% 0px 5px;
        transform: rotate(20deg);
        background-color: yellow;
        &:hover{
            cursor: pointer;
        }
    }
  }
    .flicker {
    animation: flicker 0.2s infinite alternate;
  }
  .blowed {
    opacity: 0;
  }
  @keyframes flicker {
    from { 
        opacity: 1; 
        transform: rotateY(50deg);
    }
    to { 
        opacity: 0.7; 
        transform: rotateY(10deg);
    }
  }
   .frosting {
    position: absolute;
    top: 90px;
    left:calc((100% - 220px)/2);
    width: 220px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 5px #888888;
  }
   .base {
    position: absolute;
    top: 100px;
    left:calc((100% - 240px)/2);
    width: 240px;
    height: 10px;
    background:linear-gradient(
        100deg, 
        rgba(255,255,255,100) 0%, 
        rgba(150,150,150,100) 50%,
        rgba(255,255,255,100) 80%, 
        rgba(100,100,100,100) 100%
        );
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 2px 5px #888888;
  }
   .message {
    opacity: 0;
    position: absolute;
    top: 140px;
    width: 100%;
    font-family: 'Noto Sans TC', sans-serif;
    text-align: center;
    font-size: 20px;
    color: #ff66a3;
    animation: appear 3s ease-in-out forwards;
  }
  @keyframes appear {
    to { opacity: 1; }
  }
}