.housekeeping-grid{
    position: relative;
    width:100%;
    .hk-bottom{
            width:100%;
            box-sizing:border-box;
            padding:0;
            position:relative;
            overflow-x:scroll;
            overflow-y:hidden;
            ::-webkit-scrollbar{
                display:block;
                width: 800px;
                height:20px;
            }
            ::-webkit-scrollbar-thumb{
                display:block;
                width:100px;
                border-radius:10px;
                border:6px solid transparent;
                background-clip:padding-box;
            }
        table{
            border-top:1px solid black;
            border-bottom:1px solid black;
            box-sizing: border-box;
            border-spacing: 0;
        }
        tr{
            border:none;
        }
        td{
            position: relative;
            background-color: white;
            min-width:200px;
            height: 100%;
            border:1px solid black;
            vertical-align: top;
            text-align: center;
        }
        .headcol{
            z-index: 999;
            min-width:100px;
            position: sticky;
            left:0;
            border-left:2px solid black;
            background-color: lightblue;
            font-weight: bold;
            text-align: center;
        }
        td:nth-child(even){
            background-color: lightgray;
        }
        .inner-table{
            width:100%;
            border:none;
            border-collapse: collapse;
            td{
                border-top: none;
                border-left: none;
                border-right: none;
                background-color: transparent;
            }
            tr:last-child{
                td{
                border:none;
                background-color: transparent;
                }
            }
        }
        .button-container{
            position:absolute;
            display:flex;
            width:100%;
            height:100%;
            align-items:stretch;
            gap:10px;
            .button-checkout,
            .button-checkin,
            .button-onservice{
                z-index:998;
                position:absolute;
                top:25%;
                height:50%;
                &:hover{
                    cursor:pointer
                }
            }
            .button-checkout{
                left:-10px;
                width:30%;
                border-radius: 10px;
            }
            .button-checkin{
                right:-10px;
                width:60%;
                border-radius: 10px;
            }
            .button-onservice{
                width:100%;
            }
        }
    }
}