// .ma-table{
//     width:auto;
//     border:1px solid black;
//     margin:10px;
// }

.ma-table-item{
    width:100%;
    @media only screen and (min-width:640px){
        width:200px;
    }
}